
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";

export default defineComponent({
  name: "messageSetting",
  components: {
    Header,
  },
  data() {
    return {
      isAccept: false,
      isApp: true,
      isNote: false,
      isSystem: false,
      isBroadcast: true,
    };
  },
  methods: {
    bindChangeType(ev: any, type: any) {
      switch (type) {
        case 0:
          this.isApp = ev;
          break;
        case 1:
          this.isNote = ev;
          break;
        case 2:
          this.isSystem = ev;
          break;
        case 3:
          this.isBroadcast = ev;
          break;
        default:
          this.isAccept = ev;
          break;
      }
    },
  },
});
